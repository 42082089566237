<script setup lang="ts">
import type { CSSProperties } from 'vue'
import type { DropDownProps } from '@arco-design/web-vue'

const props = withDefaults(defineProps<{
  displayType?: 'button' | 'select'
  /** 是否展示不限选项 */
  showAll?: boolean
  label?: string
  options?: Option[]
  /** type为select时才有效 */
  placeholder?: string
  position?: DropDownProps['position']
  labelClass?: string
  labelStyle?: CSSProperties
}>(), {
  showAll: true,
  displayType: 'button',
  placeholder: '请选择',
  position: 'bl',
  options: () => [],
})
const emits = defineEmits<{
  (ev: 'change', value: string, option: Option): void
}>()
const modelValue = defineModel<string>({ default: '' })
const visible = ref(false)

const { t } = useI18n()
const doptions = computed(() => {
  return props.showAll ? [{ label: t('不限'), value: '' }, ...props.options] : props.options
})
const selectedOpt = computed(() => {
  return props.options.find(o => o.value === modelValue.value)
})

function onSelect(val: Option) {
  modelValue.value = val.value
  emits('change', val.value, val)
}
</script>

<template>
  <a-dropdown
    v-model:popup-visible="visible"
    update-at-scroll
    :position="position"
    v-bind="$attrs"
    @select="onSelect($event as Option)"
  >
    <slot :scope="{ visible }">
      <a-button
        v-if="displayType === 'button'"
        size="small" type="text"
        :class="labelClass"
        :style="{ padding: '0 7px', color: 'var(--color-text-2)', ...labelStyle }"
      >
        {{ $t(label ?? '') }}
        <i
          class="icon-zhankai ml-4 text-12 transition-transform"
          :class="{ 'transform-rotate-180': visible }"
        />
      </a-button>
      <div
        v-else
        class="h-28 w-full flex cursor-pointer items-center justify-between rd-4 bg-[--color-fill-1] px-12 uno-layer-components:text-[--color-text-4]"
        :class="[{
          'outline-1 outline-solid outline-[--color-brand-6]': visible,
          'text-[--color-text-2]': !!selectedOpt,
        }, labelClass]"
      >
        {{ selectedOpt?.label ?? $t(placeholder) }}
        <i
          class="icon-zhankai ml-4 text-12 text-[--color-text-1] transition-transform"
          :class="{ 'transform-rotate-180': visible }"
        />
      </div>
    </slot>
    <template #content>
      <a-doption
        v-for="opt in doptions" :key="opt.value"
        :value="opt"
        class="h-40"
        :class="{ 'bg-[--color-brand-1] text-[--color-brand-6] font-500': modelValue === opt.value }"
      >
        <slot name="label" v-bind="opt">
          {{ opt.label }}
        </slot>
      </a-doption>
    </template>
  </a-dropdown>
</template>

<style scoped lang="less">
</style>
